<template>
  <div :class="{ 'disabled-block': isLocked }">
    <page-subtitle>
      {{ $t('pageSettings.CalcOrbits.title') }}
      <help-tooltip>
        {{ $t('pageSettings.CalcOrbitsTooltip') }}
      </help-tooltip>
      <lock-tooltip v-if="isLocked" class="tooltip-activator" />
    </page-subtitle>
    <v-radio-group v-model="calculationType" :mandatory="true" :disabled="isLocked" class="aspect-radio">
      <v-radio :label="$t('pageSettings.CalcOrbits.max')" :value="OrbCalculationType.MAX"></v-radio>
      <v-radio :label="$t('pageSettings.CalcOrbits.avg')" :value="OrbCalculationType.AVG"></v-radio>
      <v-radio :label="$t('pageSettings.CalcOrbits.min')" :value="OrbCalculationType.MIN"></v-radio>
    </v-radio-group>
  </div>
</template>
<script>
import { OrbCalculationType } from 'astro-chart';
import { PageSubtitle } from '@/elements/components/page';
import HelpTooltip from '@/elements/components/HelpTooltip';
import LockTooltip from '@/elements/components/LockTooltip';

export default {
  name: 'AspectsSettings',
  components: {
    PageSubtitle,
    HelpTooltip,
    LockTooltip,
  },
  props: {
    value: {
      type: String,
    },
    isLocked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      OrbCalculationType,
    };
  },
  computed: {
    calculationType: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style lang="sass" scoped>
.tooltip-activator
  margin-left: base-unit(3)

.aspect-radio
  ::v-deep .v-radio:not(.v-item--active) .v-icon.v-icon
    color: grey-color('75') !important


.disabled-block
  .page-subtitle
    color: grey-color('75')
  ::v-deep label
    color: grey-color('75') !important

  ::v-deep .theme--light.v-input--selection-controls.v-input--is-disabled:not(.v-input--indeterminate) .v-icon
    color: grey-color('75') !important
</style>
