<template>
  <page-template :title="$t('pageSettings.title')" class="page">
    <template v-slot:header v-if="!isMobileScreen">
      <div class="header">
        <span v-if="isChangesMade" class="header__caption">
          {{ $t('pageSettings.saved') }}
        </span>
        <AstroButton @click="onSave" class="header__button" :title="$t('pageSettings.save')" />
      </div>
    </template>
    <div class="chart-settings">
      <settings-block :title="$t('pageSettings.common')">
        <div class="chart-settings-block">
          <div class="settings-row">
            <zoom-settings v-model="localSettings.zoomEnabled" />
            <show-aspects-settings :value="isConvergingAspectsEnabled" @change="onConvergingAspectsChange" />
          </div>
          <div class="settings-row">
            <base-house-system-settings v-model="localSettings.houseSystem">
              {{ $t('pageSettings.houseSystem') }}
              <help-tooltip>
                {{ $t('pageSettings.houseSystemTooltip') }}
              </help-tooltip>
            </base-house-system-settings>
            <base-house-system-settings v-model="localSettings.northernHouseSystem">
              {{ $t('pageSettings.houseSystemForNorthMaps') }}
              <help-tooltip>
                {{ $t('pageSettings.northHouseTooltip') }}
              </help-tooltip>
            </base-house-system-settings>
            <north-house-system-slider
              v-model="localSettings.northernSystemDegree"
              :isLocked="!hasAccess(TARIFF_PLAN.PREMIUM)"
            />
          </div>
        </div>
      </settings-block>
      <settings-block :title="$t('pageSettings.Orbits')" class="chart-settings-block">
        <template v-slot:tooltip>
          <help-tooltip>
            {{ $t('pageSettings.OrbitsTooltip') }}
          </help-tooltip>
        </template>
        <div class="settings-row">
          <!-- <orbs-preset-settings v-model="localSettings.presetType" :isLocked="!hasAccess(TARIFF_PLAN.PREMIUM)" />-->
          <aspects-settings v-model="localSettings.calculatedOrbType" :isLocked="!hasAccess(TARIFF_PLAN.PREMIUM)" />
        </div>
        <orbs-settings
          :aspects-config="localSettings.aspectsConfig"
          :orbs-config="localSettings.orbsConfig"
          @aspect-change="onAspectChange"
          @orb-change="onOrbChange"
          @table-reset="onResetTable"
        />
      </settings-block>
    </div>
    <AstroButton v-if="isMobileScreen" @click="onSave" class="header__button" :title="$t('pageSettings.save')" />
  </page-template>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { AspectType } from 'astro-chart';
import cloneDeep from 'lodash/cloneDeep';

import { PageTemplate } from '@/elements/components/page';
import OrbsSettings from './OrbsSettings';
import BaseHouseSystemSettings from './BaseHouseSystemSettings';
import ZoomSettings from './ZoomSettings';
import AspectsSettings from './AspectsSettings';
import OrbsPresetSettings from './OrbsPresetSettings';
import { TARIFF_PLAN } from '@/types';
import AstroButton from '@/elements/components/AstroButton';
import SettingsBlock from './SettingsBlock';
import ShowAspectsSettings from './ShowAspectsSettings';
import NorthHouseSystemSlider from '@/elements/pages/chart-settings/NorthHouseSystemSlider';
import { getDefaultUserSettings } from '@/store/modules/user';
import HelpTooltip from '@/elements/components/HelpTooltip';
import { transformApiConfigToLibConfig } from '@/store/modules/user/utils/orbs-config';

export default {
  name: 'ChartSettingsPage',
  components: {
    AstroButton,
    NorthHouseSystemSlider,
    ZoomSettings,
    BaseHouseSystemSettings,
    OrbsSettings,
    PageTemplate,
    AspectsSettings,
    SettingsBlock,
    ShowAspectsSettings,
    OrbsPresetSettings,
    HelpTooltip,
  },
  data() {
    return {
      TARIFF_PLAN,
      localSettings: getDefaultUserSettings(),
      stringifiedSettings: '',
      stringifiedLocalSettings: '',
    };
  },
  watch: {
    settings: {
      handler(newSettings) {
        this.stringifiedSettings = JSON.stringify(newSettings);
      },
      deep: true,
    },
    localSettings: {
      handler(newLocalSettings) {
        this.stringifiedLocalSettings = JSON.stringify(newLocalSettings);
      },
      deep: true,
    },
    'localSettings.presetType'(value) {
      this.setOrbsPresetConfig(value);
    },
  },
  computed: {
    ...mapState('UserModule', ['settings', 'orbPresets']),
    isConvergingAspectsEnabled() {
      return this.localSettings.calculatedAspectType === AspectType.CONVERGING;
    },
    isChangesMade() {
      return this.stringifiedLocalSettings !== this.stringifiedSettings;
    },
  },
  methods: {
    ...mapActions('UserModule', ['updateChartSettings', 'addNotification', 'getOrbPresets']),
    onConvergingAspectsChange(isChecked) {
      this.localSettings.calculatedAspectType = isChecked ? AspectType.CONVERGING : null;
    },
    onAspectChange({ chartType, aspect, value }) {
      if (this.localSettings.presetType !== 'CUSTOM') {
        this.localSettings.presetType = 'CUSTOM';
      }
      this.localSettings.aspectsConfig[chartType][aspect].active = value;
    },
    onOrbChange({ chartType, planetName, aspectName, value }) {
      if (this.localSettings.presetType !== 'CUSTOM') {
        this.localSettings.presetType = 'CUSTOM';
      }
      this.localSettings.orbsConfig[chartType][planetName][aspectName] = value;
    },
    async onSubmit() {
      await this.onSave();
    },
    async onReject() {
      if (this.$i18n.locale === 'en') {
        this.addNotification({ text: 'Your settings have been changed' });
      } else if (this.$i18n.locale === 'de') {
        this.addNotification({ text: 'Änderungen in den Einstellungen gespeichert' });
      }
    },
    async onSave() {
      if (!this.isChangesMade) {
        return;
      }

      const localSettingsCopy = cloneDeep(this.localSettings);
      await this.updateChartSettings(localSettingsCopy);
      if (this.$i18n.locale === 'en') {
        this.addNotification({ text: 'Your settings have been changed' });
      } else if (this.$i18n.locale === 'de') {
        this.addNotification({ text: 'Änderungen in den Einstellungen gespeichert' });
      }
    },
    onResetTable() {
      this.localSettings = cloneDeep(this.settings);
    },
    setOrbsPresetConfig(presetType) {
      const currentPreset = this.orbPresets.find(({ type }) => type === presetType);

      if (!currentPreset) {
        return;
      }
      const { type, orbs } = currentPreset;
      const config = transformApiConfigToLibConfig(orbs);

      this.localSettings.orbsConfig = config.orbsConfig;
      this.localSettings.aspectsConfig = config.aspectsConfig;
      this.localSettings.presetType = type;
    },
  },
  mounted() {
    if (!this.orbPresets.length) {
      this.getOrbPresets();
    }

    this.localSettings = cloneDeep(this.settings);
    this.stringifiedSettings = JSON.stringify(this.settings);
  },
  async beforeRouteLeave(to, from, next) {
    if (this.isChangesMade) {
      if (this.$i18n.locale === 'en') {
        const isSubmitted = await this.$showConfirmDialog(
          'There are unsaved changes in your settings.',
          'Save',
          'Leave the page',
        );
        if (isSubmitted) {
          await this.onSubmit();
        } else {
          await this.onReject();
        }
      } else if (this.$i18n.locale === 'de') {
        const isSubmitted = await this.$showConfirmDialog(
          'Es gibt ungespeicherte Änderungen in den Einstellungen.',
          'Speichern',
          'Seite verlassen?',
        );
        if (isSubmitted) {
          await this.onSubmit();
        } else {
          await this.onReject();
        }
      }

      next();
    } else {
      next();
    }
  },
};
</script>

<style scoped lang="sass">
$shadow-color-from: rgba(22, 126, 162, 0.15)
$shadow-color-to: rgba(0, 0, 0, 0.15)

.page
  max-width: base-unit(1370)

.header
  display: flex
  align-items: center
  margin-left: auto

  &__caption
    font-weight: 400
    font-size: $base-font-size
    color: grey-color('75')
    margin-right: base-unit(16)

  &__button
    position: fixed
    bottom: base-unit(20)
    left: 0
    right: 0
    margin: 0 auto
    height: base-unit(40)
    width: base-unit(140)
    box-shadow: 0 4px 16px $shadow-color-from, 0 4px 16px $shadow-color-to

    +medium-devices
      position: unset
      height: base-unit(42)
      width: base-unit(150)

.chart-settings
  flex-direction: column
  width: 100%
  height: 100%
  padding-bottom: base-unit(80)
  +medium-devices
    padding-bottom: 0

.chart-settings-block
  .settings-row
    display: grid
    grid-template-columns: 1fr
    border-bottom: base-unit(2) solid #F2F2F2
    margin-bottom: base-unit(20)
    &:first-child
      padding-bottom: base-unit(20)
    +medium-devices
      grid-template-columns: 1fr 1fr 1fr
      gap: base-unit(40)
</style>
