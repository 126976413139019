<template>
  <div>
    <div class="settings-block-title" @click="toggleBlock">
      {{ title }}
      <img :src="require('@/assets/icons/grey-arrow.svg')" :class="{ test: !showSlotData }" />
      <slot name="tooltip" />
    </div>
    <transition name="collapse">
      <div v-show="showSlotData">
        <slot />
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: 'SettingsBlock',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showSlotData: true,
    };
  },
  methods: {
    toggleBlock() {
      this.showSlotData = !this.showSlotData;
    },
  },
};
</script>
<style lang="sass" scoped>
.settings-block-title
  display: flex
  cursor: pointer
  width: fit-content
  margin-bottom: base-unit(20)
  color: grey-color('500')
  font-weight: 500
  font-size: base-unit(16)
  +medium-devices
    font-size: base-unit(16)
  img
    transition: all 0.5s
    &.test
      transform: rotate(180deg)

.collapse-enter-active, .collapse-leave-active
  transition: all .3s

.collapse-enter, .collapse-leave-to
  min-height: fit-content
  opacity: 0
</style>
