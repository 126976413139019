<template>
  <div :class="{ 'disabled-block': isLocked }">
    <page-subtitle>
      {{ $t('pageSettings.northMap') }}
      <help-tooltip>
        {{ $t('pageSettings.northMapFunction') }}
      </help-tooltip>
      <lock-tooltip v-if="isLocked" class="tooltip-activator" />
    </page-subtitle>
    <v-slider
      v-model="sliderValue"
      @click:append="onAddClick"
      @click:prepend="onSubtractClick"
      :max="90"
      :min="0"
      :thumb-size="14"
      class="slider"
      thumb-label="always"
      append-icon="add"
      prepend-icon="remove"
      color="primary"
      thumb-color="primary"
      track-color="#C3D7DD"
      :disabled="isLocked"
    />
  </div>
</template>

<script>
import { PageSubtitle } from '@/elements/components/page';
import HelpTooltip from '@/elements/components/HelpTooltip';
import LockTooltip from '@/elements/components/LockTooltip';

export default {
  name: 'NorthHouseSystemSlider',
  components: {
    PageSubtitle,
    HelpTooltip,
    LockTooltip,
  },
  props: {
    value: {
      type: Number,
      required: true,
    },
    isLocked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    sliderValue: {
      set(value) {
        this.triggerUpdate(value);
      },
      get() {
        return this.value;
      },
    },
  },
  methods: {
    triggerUpdate(value) {
      this.$emit('input', value);
    },
    onAddClick() {
      this.triggerUpdate(this.value + 1);
    },
    onSubtractClick() {
      this.triggerUpdate(this.value - 1);
    },
  },
};
</script>

<style scoped lang="sass">
.slider
  margin-top: base-unit(20)
  max-width: unset
  padding: 0 base-unit(10)
  +medium-devices
    padding: 0
    max-width: base-unit(242)

  ::v-deep .v-slider__thumb-label
    background: transparent !important
    color: grey-color('500')
    height: base-unit(10) !important

.tooltip-activator
  margin-left: base-unit(3)

.disabled-block
  .page-subtitle
    color: grey-color('500')
  ::v-deep label
    color: grey-color('500') !important

  .slider
    ::v-deep .primary
      background-color: rgba(0, 0, 0, 0.26) !important
      background-color: rgba(0, 0, 0, 0.26) !important
</style>
