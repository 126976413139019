import { render, staticRenderFns } from "./ZoomSettings.vue?vue&type=template&id=2bca924e&scoped=true&"
import script from "./ZoomSettings.vue?vue&type=script&lang=js&"
export * from "./ZoomSettings.vue?vue&type=script&lang=js&"
import style0 from "./ZoomSettings.vue?vue&type=style&index=0&id=2bca924e&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bca924e",
  null
  
)

export default component.exports