<template>
  <div class="zoom-settings">
    <page-subtitle>
      {{ $t('pageSettings.zoom') }}
      <help-tooltip>
        {{ $t('pageSettings.zoomToolTip') }}
      </help-tooltip>
    </page-subtitle>
    <div class="zoom-settings__content">
      <check-box hide-details :input-value="value.desktop" @change="onZoomEnabledChange('desktop', $event)">
        {{ $t('pageSettings.desktop') }}
      </check-box>
      <check-box hide-details :input-value="value.mobile" @change="onZoomEnabledChange('mobile', $event)">
        {{ $t('pageSettings.mobile') }}
      </check-box>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import { PageSubtitle } from '@/elements/components/page';
import CheckBox from '@/elements/components/check-box';
import HelpTooltip from '@/elements/components/HelpTooltip';

export default {
  name: 'ZoomSettings',
  components: {
    CheckBox,
    PageSubtitle,
    HelpTooltip,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions('UserModule', ['setZoomEnabled']),
    onZoomEnabledChange(type, value) {
      this.$emit('input', {
        ...this.value,
        [type]: value,
      });
    },
  },
};
</script>

<style scoped lang="sass">
.zoom-settings
  margin-bottom: base-unit(20)
  +medium-devices
    margin-bottom: 0
</style>
