<template>
  <div class="show-aspects-settings">
    <page-subtitle>{{ $t('pageSettings.aspects') }}</page-subtitle>
    <check-box hide-details :input-value="value" v-on="$listeners">
      {{ $t('pageSettings.showAspects') }}
    </check-box>
  </div>
</template>
<script>
import { PageSubtitle } from '@/elements/components/page';
import CheckBox from '@/elements/components/check-box';

export default {
  name: 'ShowAspectsSettings',
  components: {
    CheckBox,
    PageSubtitle,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
