<template>
  <table>
    <thead>
      <tr>
        <th class="aspect-column"></th>
        <th v-for="aspectable in aspectingObjectsList" :key="aspectable.name">
          <div class="content-padding">
            <img
              v-if="aspectable.image"
              :src="aspectable.image"
              :alt="aspectable.label"
              :width="imgSize"
              :height="imgSize"
            />
            <span v-else>
              {{ aspectable.label }}
            </span>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="aspect in aspectList" :key="aspect.name">
        <td class="aspect-column">
          <check-box :input-value="aspect.active" @change="onAspectCheck(aspect.name, !aspect.active)" hide-details>
            <span class="aspect-column__title">
              {{ aspect.angle }}
            </span>
          </check-box>
        </td>
        <td v-for="aspectable in aspectingObjectsList" :key="aspectable.name">
          <v-text-field
            type="number"
            step="any"
            hide-details
            dense
            flat
            solo
            :value="orbs[aspectable.name][aspect.name]"
            :label="`${aspectable.label} орбис`"
            @input="onOrbChange(aspectable.name, aspect.name, $event)"
            class="field"
          >
          </v-text-field>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { ChartType, Planet } from 'astro-chart';

import { ASPECT_NAME_TRANSLATION, CUSP_NUMERICAL_NAMES, PLANET_NAMES_TRANSLATION } from '@/types';
import CheckBox from '@/elements/components/check-box';

export default {
  name: 'OrbsTable',
  components: { CheckBox },
  props: {
    aspects: {
      type: Object,
      required: true,
    },
    orbs: {
      type: Object,
      required: true,
    },
    chartType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      imgSize: 20,
    };
  },
  computed: {
    planetImages() {
      return Object.values(Planet).reduce((acc, planet) => {
        acc[planet] = {
          name: planet,
          // eslint-disable-next-line no-undef
          path: require(`@/assets/planets/${planet}.svg`),
        };
        return acc;
      }, {});
    },
    aspectingObjectsList() {
      const list = Object.values(Planet).map((name) => ({
        name,
        label: PLANET_NAMES_TRANSLATION[name],
        image: this.planetImages[name].path,
      }));

      if (this.chartType === ChartType.TRANSIT) {
        list.push(
          ...Object.values(CUSP_NUMERICAL_NAMES).map((name) => ({
            name,
            label: name,
          })),
        );
      }

      return list;
    },
    aspectList() {
      return Object.keys(ASPECT_NAME_TRANSLATION).reduce((acc, aspectName) => {
        if (this.aspects[aspectName]) {
          acc.push({
            name: aspectName,
            label: ASPECT_NAME_TRANSLATION[aspectName],
            angle: this.aspects[aspectName].angle,
            active: this.aspects[aspectName].active,
          });
        }
        return acc;
      }, []);
    },
  },
  methods: {
    onOrbChange(planet, aspect, value) {
      this.$emit('orb-change', {
        planet,
        aspect,
        value,
      });
    },
    onAspectCheck(aspect, value) {
      this.$emit('aspect-change', {
        aspect,
        value,
      });
    },
  },
};
</script>

<style scoped lang="sass">
$table-light-blue: blue-color('200')
$border-radius-value: base-unit(5)

::v-deep input
  color: grey-color('500') !important

img
  opacity: 0.6

table
  border-spacing: 0
  width: 100%

th
  height: base-unit(40)
  border-bottom: base-unit(2) solid #F2F2F2
  border-top: base-unit(2) solid #F2F2F2
  padding: base-unit(15) 0

td
  height: base-unit(32)
  min-width: base-unit(50)

.v-input--selection-controls
  margin-top: 0 !important

.aspect-column
  min-width: base-unit(100)

  &__title
    font-weight: 400
    color: grey-color('500')

.content-padding
  display: flex
  align-items: center
  padding: 0 base-unit(10)
</style>
