<template>
  <div>
    <page-subtitle>
      <slot></slot>
    </page-subtitle>
    <v-radio-group v-model="houseSystem" :mandatory="true" class="base-house-system-radio">
      <v-radio v-for="item in houseSystemItems" :value="item.value" :key="item.value">
        <template v-slot:label>
          <div>
            {{ $t(item.text) }}
            <help-tooltip>
              {{ $t(tooltipText(item.key)) }}
            </help-tooltip>
          </div>
        </template>
      </v-radio>
    </v-radio-group>
  </div>
</template>

<script>
import { HOUSE_SYSTEM_TYPE } from '@/types';
import { PageSubtitle } from '@/elements/components/page';
import HelpTooltip from '@/elements/components/HelpTooltip';

const houseSystemItems = Object.keys(HOUSE_SYSTEM_TYPE).map((key) => ({
  text: HOUSE_SYSTEM_TYPE[key],
  value: key,
}));

export default {
  name: 'BaseHouseSystemSettings',
  components: {
    PageSubtitle,
    HelpTooltip,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      houseSystemItems,
    };
  },
  computed: {
    houseSystem: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    tooltipText: () => (houseSystemType) => {
      switch (houseSystemType) {
        case HOUSE_SYSTEM_TYPE.PLACIDUS:
        case HOUSE_SYSTEM_TYPE.KOCH:
          return 'pageSettings.houseTooltip1';
        case HOUSE_SYSTEM_TYPE.EQUAL:
          return 'pageSettings.houseTooltip2';
        case HOUSE_SYSTEM_TYPE.EQUAL_MC:
          return 'pageSettings.houseTooltip3';
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.base-house-system-radio
  ::v-deep .v-radio:not(.v-item--active) .v-icon.v-icon
    color: grey-color('75') !important
</style>
