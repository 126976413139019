<template>
  <div :class="{ 'disabled-block': isLocked }">
    <page-subtitle>
      {{ $t('pageSettings.PresetOrbits') }}
      <lock-tooltip v-if="isLocked" class="tooltip-activator" />
    </page-subtitle>
    <v-radio-group v-model="preset" :mandatory="true" :disabled="isLocked">
      <v-radio
        v-for="preset in presetItems"
        :key="$t(preset.value)"
        :label="$t(preset.value)"
        :value="$t(preset.value)"
      />
    </v-radio-group>
  </div>
</template>

<script>
import { PageSubtitle } from '@/elements/components/page';
import LockTooltip from '@/elements/components/LockTooltip';

export default {
  name: 'OrbsPresetSettings',
  components: { LockTooltip, PageSubtitle },
  props: {
    value: {
      type: String,
    },
    isLocked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      presetItems: [
        { value: 'pageSettings.PresetOrbitsTypes.Astronova' },
        { value: 'pageSettings.PresetOrbitsTypes.Chronos' },
        { value: 'pageSettings.PresetOrbitsTypes.Sotis' },
        { value: 'pageSettings.PresetOrbitsTypes.Academy' },
        /*        { label: 'Астронова', value: 'ASTRONOVA' },
        { label: 'Хронос', value: 'CHRONOS' },
        { label: 'Сотис', value: 'SOTIS' },
        { label: 'Пользовательский', value: 'CUSTOM' },*/
      ],
    };
  },
  computed: {
    preset: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style lang="sass" scoped>
.disabled-block
  .page-subtitle
    color: grey-color('400')
  ::v-deep label
    color: grey-color('400') !important
</style>
