<template>
  <div class="orbs-settings">
    <div class="orbs-settings__header">
      <page-subtitle>
        {{ $t('pageSettings.tableOrbits') }}
      </page-subtitle>
      <button @click="onResetTable" class="orbs-settings__reset-button">
        {{ $t('pageSettings.tableOrbitsReset') }}
      </button>
    </div>
    <div class="tabs-container">
      <v-tabs v-model="currentTableIndex" show-arrows>
        <v-tabs-slider :color="'#167EA2'" class="v-slide-slider"></v-tabs-slider>
        <v-tab
          class="v-slide-tab"
          active-class="v-slide-tab-active"
          v-for="tableName in tableNames"
          :key="tableName.id"
        >
          {{ $t(tableName.label) }}
        </v-tab>
      </v-tabs>
    </div>

    <div class="orbs-settings__content">
      <orbs-table
        class="orbs-settings__content-table"
        @orb-change="onOrbChange"
        @aspect-change="onAspectChange"
        :aspects="selectedAspectsConfig"
        :orbs="selectedOrbsConfig"
        :chartType="selectedChartType"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import { ChartTypeTranslation } from '@/types';

import { PageSubtitle } from '@/elements/components/page';
import OrbsTable from './OrbsTable';

const tableNames = Object.keys(ChartTypeTranslation).map((key) => ({
  id: key,
  label: ChartTypeTranslation[key],
}));

export default {
  name: 'OrbsSettings',
  components: {
    OrbsTable,
    PageSubtitle,
  },
  props: {
    orbsConfig: {
      type: Object,
      required: true,
    },
    aspectsConfig: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tableNames,
      currentTableIndex: 0,
    };
  },
  computed: {
    ...mapState('ChartModule', ['chartType']),
    selectedOrbsConfig() {
      return this.orbsConfig[this.selectedChartType];
    },
    selectedAspectsConfig() {
      return this.aspectsConfig[this.selectedChartType];
    },
    selectedTable() {
      return this.tableNames[this.currentTableIndex];
    },
    selectedChartType() {
      return this.selectedTable.id;
    },
  },
  methods: {
    ...mapActions('UserModule', ['setOrbValue', 'setAspectActiveState']),
    onOrbChange({ planet, aspect, value } = {}) {
      this.$emit('orb-change', {
        chartType: this.selectedChartType,
        planetName: planet,
        aspectName: aspect,
        value: +value,
      });
    },
    onAspectChange(data) {
      this.$emit('aspect-change', {
        ...data,
        chartType: this.selectedChartType,
      });
    },
    onResetTable() {
      this.$emit('table-reset');
    },
  },
  mounted() {
    const newIndex = Object.keys(ChartTypeTranslation).indexOf(this.chartType);
    this.currentTableIndex = newIndex === -1 ? 0 : newIndex;
  },
};
</script>

<style scoped lang="sass">
.orbs-settings
  display: flex
  flex-direction: column

  &__header
    display: flex
    align-items: flex-start
    justify-content: space-between
    flex-direction: column
    +small-devices
      flex-direction: row

  &__reset-button
    color: blue-color('primary')
    &:hover
      color: black-color('secondary')

  &__content
    overflow: auto

    &-table
      margin-top: base-unit(15)

.tabs-container
  width: calc(100wv - #{base-unit(60)})

  ::v-deep .v-slide-tab
    font-weight: 400

    &-active
      color: blue-color('primary')

    &:not(.v-tab--active)
      color: grey-color('500') !important

.page-subtitle
  color: grey-color('500')
  font-weight: 500
  font-size: base-unit(16)
</style>
